import { Route, Routes } from 'react-router-dom';
import Staff from 'src/pages/Staff';

function StaffRouter() {
  return (
    <Routes>
      <Route path="/" element={<Staff page="dashboard" />} />
      <Route path="/dashboard" element={<Staff page="dashboard" />} />
      <Route
        path="/yearbook_orders"
        element={<Staff page="yearbook_orders" />}
      />
      <Route
        path="/freshman_register_orders"
        element={<Staff page="freshman_register_orders" />}
      />
      <Route
        path="/yearbook_bioforms"
        element={<Staff page="yearbook_bioforms" />}
      />
      <Route
        path="/organization_bioforms"
        element={<Staff page="organization_bioforms" />}
      />
      <Route path="/sales" element={<Staff page="sales" />} />
      <Route path="/constants" element={<Staff page="constants" />} />
    </Routes>
  );
}

export default StaffRouter;
