import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  DataGrid,
  GridToolbar,
} from '@mui/x-data-grid';
import { useState, useMemo, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { ClearIcon } from '@mui/x-date-pickers';
import { honorsList } from 'src/constants/honorsList';
import { intercollegiateSportsList } from 'src/constants/intercollegiateSportsList';
import { houseActivitiesList } from 'src/constants/houseActivities';
import { harvardActivitiesList } from 'src/constants/harvardActivitiesList';
import { clubSportsList } from 'src/constants/clubSportsList';
import { onCampusJobsList } from 'src/constants/onCampusJobsList';
import { houseList } from 'src/constants/houseList';
import { sbConcentrationsList } from 'src/constants/concentrationList';

interface ExtracurricularPosition {
  club: string;
  position: string;
}

interface Row {
  id: string;
  submissionId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  house: string;
  classYear: number;
  email: string;
  alternateEmail: string;
  phone: string;
  studyType: number;
  concentration: string;
  concentrationSB: boolean;
  secondConcentration: string;
  secondConcentrationSB: boolean;
  secondary: string;
  languageCitation: string;
  specialConcentration: string;
  concurrentMasters: string;
  professorFirstName: string;
  professorLastName: string;
  professorDepartment: string;
  professorEmail: string;
  birthDate: string;
  country: string;
  state: string;
  city: string;
  secondarySchool: string;
  academicHonors: string[];
  intercollegiateSports: ExtracurricularPosition[];
  houseActivities: ExtracurricularPosition[];
  harvardActivities: ExtracurricularPosition[];
  clubSports: ExtracurricularPosition[];
  onCampusJobs: ExtracurricularPosition[];
  editButton: string;
}

function YearbookBioforms() {
  const [requestedData, setRequestedData] = useState<boolean>(false);
  const [searchYear, setSearchYear] = useState<number | null>(null);
  const [downloadYear, setDownloadYear] = useState<number | null>(null);

  const [rows, setRows] = useState<Row[]>([]);

  const [openEditMenu, setOpenEditMenu] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<Row | null>(null);

  const downloadYearRegistersParams = useMemo(() => {
    const params = new URLSearchParams();
    params.append('year', JSON.stringify(downloadYear));

    return params;
  }, [downloadYear]);

  useEffect(() => {
    if (!requestedData) return;

    fetch(`${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        year: searchYear,
      }),
    })
      .then((res) => res.json())
      .then((res) => setRows(res.data.rows));
  }, [requestedData]);

  const columns: readonly GridColDef[] = [
    { field: 'id', headerName: 'ID', minWidth: 50 },
    { field: 'submissionId', headerName: 'Submission ID', minWidth: 170 },
    {
      field: 'firstName',
      headerName: 'First Name',
      minWidth: 170,
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      minWidth: 170,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 170,
    },
    {
      field: 'suffix',
      headerName: 'Suffix',
      minWidth: 170,
    },
    {
      field: 'house',
      headerName: 'House',
      minWidth: 170,
    },
    {
      field: 'classYear',
      headerName: 'Class Year',
      minWidth: 170,
    },
    { field: 'email', headerName: 'Email', minWidth: 170 },
    {
      field: 'alternateEmail',
      headerName: 'Alternate Email',
      minWidth: 170,
    },
    { field: 'phone', headerName: 'Phone', minWidth: 170 },
    {
      field: 'studyType',
      headerName: 'Study Type',
      minWidth: 170,
    },
    {
      field: 'concentration',
      headerName: 'Concentration',
      minWidth: 170,
    },
    {
      field: 'concentrationSB',
      headerName: 'Concentration SB?',
      minWidth: 170,
    },
    {
      field: 'secondConcentration',
      headerName: 'Second Concentration',
      minWidth: 170,
    },
    {
      field: 'secondConcentrationSB',
      headerName: 'Second Concentration SB?',
      minWidth: 170,
    },
    {
      field: 'secondary',
      headerName: 'Secondary',
      minWidth: 170,
    },
    {
      field: 'languageCitation',
      headerName: 'Language Citation',
      minWidth: 170,
    },
    {
      field: 'specialConcentration',
      headerName: 'Special Concentration',
      minWidth: 170,
    },
    {
      field: 'concurrentMasters',
      headerName: 'Concurrent Masters',
      minWidth: 170,
    },
    {
      field: 'professorFirstName',
      headerName: 'Professor First Name',
      minWidth: 170,
    },
    {
      field: 'professorLastName',
      headerName: 'Professor Last Name',
      minWidth: 170,
    },
    {
      field: 'professorDepartment',
      headerName: 'Professor Department',
      minWidth: 170,
    },
    {
      field: 'professorEmail',
      headerName: 'Professor Email',
      minWidth: 170,
    },
    {
      field: 'birthDate',
      headerName: 'Birth Date',
      minWidth: 170,
    },
    {
      field: 'city',
      headerName: 'City',
      minWidth: 170,
    },
    {
      field: 'state',
      headerName: 'State',
      minWidth: 170,
    },
    {
      field: 'country',
      headerName: 'Country',
      minWidth: 170,
    },
    {
      field: 'secondarySchool',
      headerName: 'Secondary School',
      minWidth: 170,
    },
    {
      field: 'academicHonors',
      headerName: 'Academic Honors',
      minWidth: 170,
      valueGetter: (value: string[]) => value.join(', '),
    },
    {
      field: 'intercollegiateSports',
      headerName: 'Intercollegiate Sports',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity: ExtracurricularPosition) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'houseActivities',
      headerName: 'House Activities',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity: ExtracurricularPosition) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'harvardActivities',
      headerName: 'Harvard Activities',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'clubSports',
      headerName: 'Club Sports',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'onCampusJobs',
      headerName: 'On Campus Jobs',
      minWidth: 170,
      valueGetter: (value: ExtracurricularPosition[]) =>
        value.length > 0
          ? value
              .map(
                (activity) =>
                  `${activity.club}${
                    activity.position !== '' ? ` (${activity.position})` : ''
                  }`,
              )
              .join('. ')
              .concat('.')
          : '',
    },
    {
      field: 'finalBioform',
      headerName: 'Bioform',
      minWidth: 170,
    },
    {
      field: 'edit',
      type: 'actions',
      headerName: 'Edit',
      minWidth: 50,
      cellClassName: 'edit',
      getActions: ({ row }: GridRowParams<Row>) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          className="textPrimary"
          onClick={() => {
            setOpenEditMenu(true);
            setEditRow(row);
          }}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography variant="h1">Yearbook Bioforms</Typography>
      </Box>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setRequestedData(true);
            }}
          >
            Get Bioforms for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={searchYear}
            onChange={(e) => {
              setSearchYear(parseInt(e.target.value, 10));
              setRequestedData(false);
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        sx={{
          pl: '10%',
          width: '80%',
          pt: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="contained"
            href={`${
              process.env.REACT_APP_BACKEND_URL
            }/staff/yearbook_bioforms${
              downloadYearRegistersParams.size > 0 ? '?' : ''
            }${downloadYearRegistersParams.toString()}`}
            target="_blank"
            rel="noreferrer"
          >
            Download CSV for:
          </Button>
        </Grid>

        <Grid item xs={4}>
          <TextField
            value={downloadYear}
            onChange={(e) => {
              setDownloadYear(parseInt(e.target.value, 10));
            }}
            size="small"
            placeholder="Class Year"
          />
        </Grid>
      </Grid>

      {requestedData && (
        <Box
          sx={{
            pl: '10%',
            width: '80%',
            pt: 4,
            display: 'grid',
            height: '800px',
          }}
        >
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            rows={rows}
            columns={columns}
            getRowId={(row) => row.submissionId}
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                csvOptions: {
                  fileName: `yearbook_bioforms_${searchYear}`,
                },
              },
            }}
          />
        </Box>
      )}

      <Dialog
        fullWidth
        open={openEditMenu}
        scroll="body"
        maxWidth="md"
        onClose={() => setOpenEditMenu(false)}
      >
        <DialogTitle>Edit Yearbook Bioform</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update this person&apos;s bioform.
          </DialogContentText>

          <br />

          <Stack direction="column" spacing={2}>
            <TextField
              label="Submission ID"
              value={editRow?.submissionId}
              disabled
            />
            <TextField
              label="First Name"
              value={editRow?.firstName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, firstName: e.target.value });
              }}
            />
            <TextField
              label="Middle Name"
              value={editRow?.middleName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, middleName: e.target.value });
              }}
            />
            <TextField
              label="Last Name"
              value={editRow?.lastName}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, lastName: e.target.value });
              }}
            />
            <TextField
              label="Suffix"
              value={editRow?.suffix}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, suffix: e.target.value });
              }}
            />
            <Autocomplete
              freeSolo
              value={editRow?.house}
              onChange={(_e, newValue) => {
                if (newValue && editRow)
                  setEditRow({
                    ...editRow,
                    house: newValue,
                  });
              }}
              options={houseList}
              renderInput={(params) => (
                <TextField {...params} label="House" variant="outlined" />
              )}
            />
            <TextField
              label="Class Year"
              value={editRow?.classYear}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    classYear: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Email"
              value={editRow?.email}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, email: e.target.value });
              }}
            />
            <TextField
              label="Alternate Email"
              value={editRow?.alternateEmail}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, alternateEmail: e.target.value });
              }}
            />
            <TextField
              label="Phone"
              value={editRow?.phone}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, phone: e.target.value });
              }}
            />
            <TextField
              label="Study Type"
              value={editRow?.studyType}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    studyType: parseInt(e.target.value, 10),
                  });
              }}
            />
            <TextField
              label="Concentration"
              value={editRow?.concentration}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, concentration: e.target.value });
              }}
            />
            {editRow?.concentration &&
              sbConcentrationsList.includes(editRow?.concentration) && (
                <FormControlLabel
                  label="Bachelor's of Science"
                  control={
                    <Checkbox
                      checked={editRow?.concentrationSB}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (editRow)
                          setEditRow({
                            ...editRow,
                            concentrationSB: e.target.checked,
                          });
                      }}
                    />
                  }
                />
              )}
            {editRow?.secondConcentration && (
              <>
                <TextField
                  label="Second Concentration"
                  value={editRow?.secondConcentration}
                  onChange={(e) => {
                    if (editRow)
                      setEditRow({
                        ...editRow,
                        secondConcentration: e.target.value,
                      });
                  }}
                />
                {editRow?.secondConcentration &&
                  sbConcentrationsList.includes(
                    editRow?.secondConcentration,
                  ) && (
                    <FormControlLabel
                      label="Bachelor's of Science"
                      control={
                        <Checkbox
                          checked={editRow?.secondConcentrationSB}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            if (editRow)
                              setEditRow({
                                ...editRow,
                                secondConcentrationSB: e.target.checked,
                              });
                          }}
                        />
                      }
                    />
                  )}
              </>
            )}
            <TextField
              label="Secondary"
              value={editRow?.secondary}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, secondary: e.target.value });
              }}
            />
            <TextField
              label="Language Citations"
              value={editRow?.languageCitation}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, languageCitation: e.target.value });
              }}
            />
            <TextField
              label="Special Concentration"
              value={editRow?.specialConcentration}
              onChange={(e) => {
                if (editRow)
                  setEditRow({
                    ...editRow,
                    specialConcentration: e.target.value,
                  });
              }}
            />
            <TextField
              label="Birth Date"
              value={editRow?.birthDate}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, birthDate: e.target.value });
              }}
            />
            <TextField
              label="City"
              value={editRow?.city}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, city: e.target.value });
              }}
            />
            <TextField
              label="State"
              value={editRow?.state}
              onChange={(e) => {
                if (editRow) setEditRow({ ...editRow, state: e.target.value });
              }}
            />
            <TextField
              label="Country"
              value={editRow?.country}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, country: e.target.value });
              }}
            />
            <TextField
              label="Secondary School"
              value={editRow?.secondarySchool}
              onChange={(e) => {
                if (editRow)
                  setEditRow({ ...editRow, secondarySchool: e.target.value });
              }}
            />
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Academic Honors</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.academicHonors)
                        setEditRow({
                          ...editRow,
                          academicHonors: [...editRow.academicHonors, ''],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.academicHonors &&
                editRow?.academicHonors.length > 0 && (
                  <Grid item xs={12}>
                    <List
                      sx={{
                        padding: 0,
                      }}
                    >
                      {editRow?.academicHonors.map((activity, ind) => (
                        <ListItem key={ind}>
                          <ListItemIcon
                            onClick={(_e) =>
                              setEditRow({
                                ...editRow,
                                academicHonors: editRow?.academicHonors.filter(
                                  (_, i) => i !== ind,
                                ),
                              })
                            }
                          >
                            <ClearIcon
                              style={{
                                cursor: 'pointer',
                                color: 'red',
                              }}
                            />
                          </ListItemIcon>
                          <Grid container direction="row" alignItems="center">
                            <Grid item xs={12} columnSpacing={1}>
                              <Autocomplete
                                freeSolo
                                value={activity}
                                getOptionDisabled={(option) =>
                                  editRow?.academicHonors.includes(option)
                                }
                                onChange={(_e, newValue) => {
                                  if (newValue)
                                    setEditRow({
                                      ...editRow,
                                      academicHonors:
                                        editRow?.academicHonors.map((val, i) =>
                                          i === ind ? newValue : val,
                                        ),
                                    });
                                }}
                                options={honorsList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Academic Honors"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
            </>
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Intercollegiate Sports</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.intercollegiateSports)
                        setEditRow({
                          ...editRow,
                          intercollegiateSports: [
                            ...editRow.intercollegiateSports,
                            { club: '', position: '' },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.intercollegiateSports &&
                editRow?.intercollegiateSports.length > 0 && (
                  <Grid item xs={12}>
                    <List
                      sx={{
                        padding: 0,
                      }}
                    >
                      {editRow?.intercollegiateSports.map((sport, ind) => (
                        <ListItem key={ind}>
                          <ListItemIcon
                            onClick={(_e) =>
                              setEditRow({
                                ...editRow,
                                intercollegiateSports:
                                  editRow?.intercollegiateSports.filter(
                                    (_, i) => i !== ind,
                                  ),
                              })
                            }
                          >
                            <ClearIcon
                              style={{
                                cursor: 'pointer',
                                color: 'red',
                              }}
                            />
                          </ListItemIcon>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            columnSpacing={1}
                          >
                            <Grid item xs={8}>
                              <Autocomplete
                                freeSolo
                                value={sport.club}
                                getOptionDisabled={(option) =>
                                  editRow?.intercollegiateSports
                                    .map((i) => i.club)
                                    .includes(option)
                                }
                                onChange={(_e, newValue) => {
                                  if (newValue)
                                    setEditRow({
                                      ...editRow,
                                      intercollegiateSports:
                                        editRow?.intercollegiateSports.map(
                                          (val, i) =>
                                            i === ind
                                              ? {
                                                  ...val,
                                                  club: newValue,
                                                }
                                              : val,
                                        ),
                                    });
                                }}
                                options={intercollegiateSportsList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Intercollegiate Sports"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Position"
                                variant="outlined"
                                value={sport.position}
                                onChange={(e) =>
                                  setEditRow({
                                    ...editRow,
                                    intercollegiateSports:
                                      editRow?.intercollegiateSports.map(
                                        (val, i) =>
                                          i === ind
                                            ? {
                                                ...val,
                                                position: e.target.value,
                                              }
                                            : val,
                                      ),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
            </>
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>House Activities</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.houseActivities)
                        setEditRow({
                          ...editRow,
                          houseActivities: [
                            ...editRow.houseActivities,
                            { club: '', position: '' },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.houseActivities &&
                editRow?.houseActivities.length > 0 && (
                  <Grid item xs={12}>
                    <List
                      sx={{
                        padding: 0,
                      }}
                    >
                      {editRow?.houseActivities.map((activity, ind) => (
                        <ListItem key={ind}>
                          <ListItemIcon
                            onClick={(_e) =>
                              setEditRow({
                                ...editRow,
                                houseActivities:
                                  editRow?.houseActivities.filter(
                                    (_, i) => i !== ind,
                                  ),
                              })
                            }
                          >
                            <ClearIcon
                              style={{
                                cursor: 'pointer',
                                color: 'red',
                              }}
                            />
                          </ListItemIcon>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            columnSpacing={1}
                          >
                            <Grid item xs={8}>
                              <Autocomplete
                                freeSolo
                                value={activity.club}
                                getOptionDisabled={(option) =>
                                  editRow?.houseActivities
                                    .map((i) => i.club)
                                    .includes(option)
                                }
                                onChange={(_e, newValue) => {
                                  if (newValue)
                                    setEditRow({
                                      ...editRow,
                                      houseActivities:
                                        editRow?.houseActivities.map(
                                          (val, i) =>
                                            i === ind
                                              ? {
                                                  ...val,
                                                  club: newValue,
                                                }
                                              : val,
                                        ),
                                    });
                                }}
                                options={houseActivitiesList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="House Activities"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Position"
                                variant="outlined"
                                value={activity.position}
                                onChange={(e) =>
                                  setEditRow({
                                    ...editRow,
                                    houseActivities:
                                      editRow?.houseActivities.map((val, i) =>
                                        i === ind
                                          ? {
                                              ...val,
                                              position: e.target.value,
                                            }
                                          : val,
                                      ),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
            </>
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Harvard Activities</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.harvardActivities)
                        setEditRow({
                          ...editRow,
                          harvardActivities: [
                            ...editRow.harvardActivities,
                            { club: '', position: '' },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.harvardActivities &&
                editRow?.harvardActivities.length > 0 && (
                  <Grid item xs={12}>
                    <List
                      sx={{
                        padding: 0,
                      }}
                    >
                      {editRow?.harvardActivities.map((activity, ind) => (
                        <ListItem key={ind}>
                          <ListItemIcon
                            onClick={(_e) =>
                              setEditRow({
                                ...editRow,
                                harvardActivities:
                                  editRow?.harvardActivities.filter(
                                    (_, i) => i !== ind,
                                  ),
                              })
                            }
                          >
                            <ClearIcon
                              style={{
                                cursor: 'pointer',
                                color: 'red',
                              }}
                            />
                          </ListItemIcon>
                          <Grid
                            container
                            direction="row"
                            alignItems="center"
                            columnSpacing={1}
                          >
                            <Grid item xs={8}>
                              <Autocomplete
                                freeSolo
                                value={activity.club}
                                getOptionDisabled={(option) =>
                                  editRow?.harvardActivities
                                    .map((i) => i.club)
                                    .includes(option)
                                }
                                onChange={(_e, newValue) => {
                                  if (newValue)
                                    setEditRow({
                                      ...editRow,
                                      harvardActivities:
                                        editRow?.harvardActivities.map(
                                          (val, i) =>
                                            i === ind
                                              ? {
                                                  ...val,
                                                  club: newValue,
                                                }
                                              : val,
                                        ),
                                    });
                                }}
                                options={harvardActivitiesList}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Harvard Activities"
                                    variant="outlined"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                fullWidth
                                label="Position"
                                variant="outlined"
                                value={activity.position}
                                onChange={(e) =>
                                  setEditRow({
                                    ...editRow,
                                    harvardActivities:
                                      editRow?.harvardActivities.map(
                                        (val, i) =>
                                          i === ind
                                            ? {
                                                ...val,
                                                position: e.target.value,
                                              }
                                            : val,
                                      ),
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                )}
            </>
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>Club Sports</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.clubSports)
                        setEditRow({
                          ...editRow,
                          clubSports: [
                            ...editRow.clubSports,
                            { club: '', position: '' },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.clubSports && editRow?.clubSports.length > 0 && (
                <Grid item xs={12}>
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {editRow?.clubSports.map((activity, ind) => (
                      <ListItem key={ind}>
                        <ListItemIcon
                          onClick={(_e) =>
                            setEditRow({
                              ...editRow,
                              clubSports: editRow?.clubSports.filter(
                                (_, i) => i !== ind,
                              ),
                            })
                          }
                        >
                          <ClearIcon
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          />
                        </ListItemIcon>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          columnSpacing={1}
                        >
                          <Grid item xs={8}>
                            <Autocomplete
                              freeSolo
                              value={activity.club}
                              getOptionDisabled={(option) =>
                                editRow?.clubSports
                                  .map((i) => i.club)
                                  .includes(option)
                              }
                              onChange={(_e, newValue) => {
                                if (newValue)
                                  setEditRow({
                                    ...editRow,
                                    clubSports: editRow?.clubSports.map(
                                      (val, i) =>
                                        i === ind
                                          ? {
                                              ...val,
                                              club: newValue,
                                            }
                                          : val,
                                    ),
                                  });
                              }}
                              options={clubSportsList}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Club Sports"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              label="Position"
                              variant="outlined"
                              value={activity.position}
                              onChange={(e) =>
                                setEditRow({
                                  ...editRow,
                                  clubSports: editRow?.clubSports.map(
                                    (val, i) =>
                                      i === ind
                                        ? {
                                            ...val,
                                            position: e.target.value,
                                          }
                                        : val,
                                  ),
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </>
            <>
              <Grid container direction="row" alignItems="center">
                <Grid item xs={6}>
                  <Typography>On Campus Jobs</Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={6}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (editRow?.onCampusJobs)
                        setEditRow({
                          ...editRow,
                          onCampusJobs: [
                            ...editRow.onCampusJobs,
                            { club: '', position: '' },
                          ],
                        });
                    }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
              {editRow?.onCampusJobs && editRow?.onCampusJobs.length > 0 && (
                <Grid item xs={12}>
                  <List
                    sx={{
                      padding: 0,
                    }}
                  >
                    {editRow?.onCampusJobs.map((activity, ind) => (
                      <ListItem key={ind}>
                        <ListItemIcon
                          onClick={(_e) =>
                            setEditRow({
                              ...editRow,
                              onCampusJobs: editRow?.onCampusJobs.filter(
                                (_, i) => i !== ind,
                              ),
                            })
                          }
                        >
                          <ClearIcon
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                            }}
                          />
                        </ListItemIcon>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          columnSpacing={1}
                        >
                          <Grid item xs={8}>
                            <Autocomplete
                              freeSolo
                              value={activity.club}
                              getOptionDisabled={(option) =>
                                editRow?.onCampusJobs
                                  .map((i) => i.club)
                                  .includes(option)
                              }
                              onChange={(_e, newValue) => {
                                if (newValue)
                                  setEditRow({
                                    ...editRow,
                                    onCampusJobs: editRow?.onCampusJobs.map(
                                      (val, i) =>
                                        i === ind
                                          ? {
                                              ...val,
                                              club: newValue,
                                            }
                                          : val,
                                    ),
                                  });
                              }}
                              options={onCampusJobsList}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="On Campus Jobs"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              fullWidth
                              label="Position"
                              variant="outlined"
                              value={activity.position}
                              onChange={(e) =>
                                setEditRow({
                                  ...editRow,
                                  onCampusJobs: editRow?.onCampusJobs.map(
                                    (val, i) =>
                                      i === ind
                                        ? {
                                            ...val,
                                            position: e.target.value,
                                          }
                                        : val,
                                  ),
                                })
                              }
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
            </>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditMenu(false)}>Cancel</Button>
          <Button
            color="error"
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                  {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Delete
          </Button>
          <Button
            onClick={() => {
              if (editRow) {
                fetch(
                  `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                  {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      submissionId: editRow.submissionId,
                      firstName: editRow.firstName,
                      middleName: editRow.middleName,
                      lastName: editRow.lastName,
                      suffix: editRow.suffix,
                      house: editRow.house,
                      classYear: editRow.classYear,
                      email: editRow.email,
                      alternateEmail: editRow.alternateEmail,
                      phone: editRow.phone,
                      studyType: editRow.studyType,
                      concentration: editRow.concentration,
                      concentrationSB: editRow.concentrationSB,
                      secondConcentration: editRow.secondConcentration,
                      secondConcentrationSB: editRow.secondConcentrationSB,
                      secondary: editRow.secondary,
                      languageCitation: editRow.languageCitation,
                      specialConcentration: editRow.specialConcentration,
                      concurrentMasters: editRow.concurrentMasters,
                      professorFirstName: editRow.professorFirstName,
                      professorLastName: editRow.professorLastName,
                      professorDepartment: editRow.professorDepartment,
                      professorEmail: editRow.professorEmail,
                      birthDate: editRow.birthDate,
                      country: editRow.country,
                      state: editRow.state,
                      city: editRow.city,
                      secondarySchool: editRow.secondarySchool,
                      academicHonors: editRow.academicHonors,
                      intercollegiateSports: editRow.intercollegiateSports,
                      houseActivities: editRow.houseActivities,
                      harvardActivities: editRow.harvardActivities,
                      clubSports: editRow.clubSports,
                      onCampusJobs: editRow.onCampusJobs,
                    }),
                  },
                )
                  .then((res) => res.json())
                  .then((res) => {
                    if (res.status === 'success') {
                      setOpenEditMenu(false);

                      fetch(
                        `${process.env.REACT_APP_BACKEND_URL}/staff/yearbook_bioforms`,
                        {
                          method: 'POST',
                          credentials: 'include',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            year: searchYear,
                          }),
                        },
                      )
                        .then((updateRes) => updateRes.json())
                        .then((updateRes) => setRows(updateRes.data.rows));
                    }
                  });
              }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default YearbookBioforms;
